.icon-style {
  color: white;
  display: block;
  font-size: 16px;
  margin-bottom: 24px;
  opacity: 0.6;
  transition: all .2s ease;
}

.icon-style:hover {
  opacity: 1;
  color: #FAED00;
}

.icon-style:last-child {
  margin-right: 0;
  margin-bottom: 0px;
}
