.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.title {
  color: #FAED00;
  opacity: 1;
  display:block;
  font-size: 48px;
  letter-spacing: 0px;
  line-height: 48px;
  transition: all 0.2s ease-out;

  /* margin-right: 8px */
}
.subtitle {
  font-size: 16px;
  opacity: 0.5;
  letter-spacing: 0px;
  display: block;
  transition: all 0.4s ease-out;
  text-align: center;
}

.App-link {
  opacity: 0;
  color: #FAED00;
  font-size: 14px;
  letter-spacing: 0px;
  text-decoration: none;
  transition: all 0.5s ease;
  display: block;
  text-align: center;
}


.App-header--content:hover {
  cursor: pointer;
}

.App-header--content:hover .title {
  opacity: 1;
  /* font-size: 50px; */
  transform: scale(1.1,1.1);
}

.App-header--content:hover .subtitle {
  /* font-size: 48px; */
  opacity: .8;
  transform: translate3d(0, 5px, 0);
  /* transform: scale(1.1,1.1); */

  /* margin-top: 2px; */
}

.App-header--content:hover .App-link {
  /* font-size: 48px; */
  opacity: 1;
  transform: translate3d(0, 20px, 0);
  /* margin-top: 2px; */
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link:hover {
  color: greenyellow;
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
