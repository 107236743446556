@import url('https://fonts.googleapis.com/css2?family=Abel&family=Antic&display=swap');

@font-face {
  font-family: 'elektron_pixel_fontregular';
  src: url('./fonts/elektron_pixel_font-webfont.woff2') format('woff2'),
       url('./fonts/elektron_pixel_font-webfont.woff') format('woff'),
       url('./fonts/elektron_pixel_font-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: 'Abel', sans-serif; */
  font-family: 'elektron_pixel_fontregular', monospace, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


body {
	background: linear-gradient(-45deg, #282c34, #000, #11141a, #000);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;

  height: 100vh;
  width: 100vw;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: white;
  text-decoration: none;
}